import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
import Consumers from '@/blocks/faqs/consumers.mdx';
import Businesses from '@/blocks/faqs/businesses.mdx';
import SelectTabs from '@/components/FAQ/SelectTabs';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SelectTabs mdxType="SelectTabs">
  <Consumers key="For Consumers" mdxType="Consumers" />
  <Businesses key="For Businesses" mdxType="Businesses" />
    </SelectTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      